<template>
  <div class="about marquee">

    <div style="width:100%;display: flex;justify-content: center;">

    <count-up  ref="count1" :value="value" :from="from" decimals="0" @sendValue="acceptValue1">
      <div class="changeNum" >{{currentNum1}}</div>
      <div style="color:white;font-size:30px;margin-top:-80px;">电视</div>
      <div><span class="result-tag-red" v-if="results.nums_first[0]>0">上：{{ results.nums_first[0] }}</span><span v-if="results.nums_middle[0]>0" class="result-tag-green">中：{{ results.nums_middle[0] }}</span><span v-if="results.nums_last[0]>0" class="result-tag-blue">下：{{ results.nums_last[0] }}</span></div>

    </count-up>

    <count-up   ref="count2"  :value="value" :from="from" decimals="0" @sendValue="acceptValue2">
      <div class="changeNum" >{{currentNum2}}</div>
      <div style="color:white;font-size:30px;margin-top:-80px;">广播</div>
      <div ><span class="result-tag-red" v-if="results.nums_first[1]>0">上：{{ results.nums_first[1] }}</span><span v-if="results.nums_middle[1]>0" class="result-tag-green">中：{{ results.nums_middle[1] }}</span><span v-if="results.nums_last[1]>0" class="result-tag-blue">下：{{ results.nums_last[1] }}</span></div>
    </count-up>

    <count-up  ref="count3"  :value="value" :from="from" decimals="0" @sendValue="acceptValue3">
      <div class="changeNum" >{{currentNum3}}</div>
      <div style="color:white;font-size:30px;margin-top:-80px;">报纸</div>
      <div ><span class="result-tag-red" v-if="results.nums_first[2]>0">上：{{ results.nums_first[2] }}</span><span v-if="results.nums_middle[2]>0" class="result-tag-green">中：{{ results.nums_middle[2] }}</span><span v-if="results.nums_last[2]>0" class="result-tag-blue">下：{{ results.nums_last[2] }}</span></div>
    </count-up>
<!--
    <count-up :hidden="cnt!=0 && cnt<4" ref="count4"  :value="value" decimals="0" @sendValue="acceptValue4">
      <div class="changeNum" >{{currentNum4}}</div>
    </count-up>

    <count-up :hidden="cnt!=0 && cnt<5" ref="count5"  :value="value" decimals="0"  @sendValue="acceptValue5">
      <div class="changeNum" >{{currentNum5}}</div>
    </count-up>
-->
    </div>

    <div class="control-area">
      <!--span style="color:white;font-size:25px;margin-right: 10px;">天数</span-->
      <!-- @contextmenu.prevent="onKeydown({keyCode:32},'first')" @keydown.prevent="onKeydown($event,'first')" -->
      <button v-if="!isstarted && !buttonIdx" class="btn" @click="onKeydown({keyCode:13},'first')" >上旬</button>
      <button v-else-if="isstarted && buttonIdx == 'first'"  class="btn" style="background-color: #e93d52;" @click="onKeydown({keyCode:13},'first')"  >停止</button>
      <button v-else class="btn" disabled style="background-color: lightgray;">上旬</button>


      <button v-if="!isstarted && !buttonIdx" class="btn" @click="onKeydown({keyCode:13},'middle')" >中旬</button>
      <button v-else-if="isstarted && buttonIdx == 'middle'" class="btn" style="background-color: #e93d52;" @click="onKeydown({keyCode:13},'middle')" >停止</button>
      <button v-else class="btn" disabled style="background-color: lightgray;">中旬</button>


      <button v-if="!isstarted && !buttonIdx" class="btn" @click="onKeydown({keyCode:13},'last')">下旬</button>
      <button v-else-if="isstarted && buttonIdx == 'last'"   class="btn" style="background-color: #e93d52;" @click="onKeydown({keyCode:13},'last')">停止</button>
      <button v-else class="btn" disabled style="background-color: lightgray;">上旬</button>
    </div>

    <div v-if="is_admin" style="margin-top: 40px;">
      <span class="cnt">本月天数</span><input v-model="lastday" class="input-num" type="number" min="1"  max="31" disabled></input>
      <div style="margin-top:20px;"><span class="period-setting" style="margin-left:60px;">上旬</span><span class="period-setting">中旬</span><span class="period-setting">下旬</span></div>
      <div style="margin:10px 0 10px 0;"><span class="cnt">电视</span><input  type="number" min="0" :max="lastday" v-for="(item,index) in fix_nums"   v-model="fix_nums[index][0]" class="input-num"></input></div>
      <div style="margin:10px 0 10px 0;"><span class="cnt">广播</span><input  type="number" min="0" :max="lastday" v-for="(item,index) in fix_nums"  v-model="fix_nums[index][1]" class="input-num"></input></div>
      <div style="margin:10px 0 10px 0;"><span class="cnt">报纸</span><input  type="number" min="0" :max="lastday" v-for="(item,index) in fix_nums"    v-model="fix_nums[index][2]" class="input-num"></input></div>

      <button class="btn" style="background-color: #e93d52;width:300px;" @click="onSave">保存</button>
    </div>


  </div>
</template>

<script>

import countUp from './countUp.vue';  //下拉框带popup蒙层

export default {
  name:'cecountUp',
  components: { //注册组件
    countUp,
  },

  data() {
    return {

      lastday:31,
      from:0,
      value:31,
      is_admin:false,

      currentNum1:"0", //当前数值
      currentNum2:"0", //当前数值
      currentNum3:"0", //当前数值

      /*控制当前点击按钮，上旬、中旬、下旬*/
      results:{
        nums_first:["0","0","0"],
        nums_middle:["0","0","0"],
        nums_last:["0","0","0"]
      },

      /*设定数值*/
      fix_nums:[
        ["1","1","1"],
        ["11","11","11"],
        ["21","21","21"],
      ],

      /*生成次数*/
      start_times:[0,0,0],

      buttonIdx:null,
      isstarted:false,

    };
  },

  mounted(){

    if (window.location.pathname.includes('/admin'))
      this.is_admin=true;
    else
      this.is_admin=false;

    this.loadData();

    this.setFromValue();
  },

  methods: {

    getSettings(){

    },

    getLastDay(){
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth(); // 获取当前月份（从0开始，0是1月）
      const nextMonth = currentMonth + 1; // 获取下一个月
      const lastDayOfMonth = new Date(currentDate.getFullYear(), nextMonth, 0); // 获取下一个月的最后一天
      return lastDayOfMonth.getDate()
    },

    setFromValue(){

      this.lastday = this.getLastDay();

      if(this.buttonIdx == 'first') {
        this.from = 0;
        this.value = 10;
      }
      else if (this.buttonIdx == 'middle'){
        this.from = 10;
        this.value = 20;
      }
      else if (this.buttonIdx == 'last'){
        this.from = 20;
        this.value = this.lastday;
      }
      else{
        this.from = 0;
        this.value = this.lastday;
      }
    },

    postLuckyAppData(key, value) {
      const url = '/api/lucky-app/add?key=4A6F70D4-54C9-440C-823A-01FB1BC33C54';
      const data = {
        name: key,
        value: value
      };

      return fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data) // 将数据对象转换为 JSON 字符串
      })
          .then(response => {
            if (!response.ok) {
              // 如果响应状态不是成功，抛出一个错误
              throw new Error('Network response was not ok');
            }
            return response.json(); // 解析响应为 JSON 格式
          })
          .then(data => {
            console.log('Data successfully posted:', data); // 处理返回的数据
            return { success: true, data }; // 返回成功结果
          })
          .catch(error => {
            console.error('Error:', error); // 处理错误
            return { success: false, error: error.message }; // 返回错误信息
          });
    },

    onSave(){

      // 检查第一行的三个数字是否都不超过 10
      if (this.fix_nums[0].some(num => parseInt(num) > 10) || this.fix_nums[0].some(num => parseInt(num) < 1)) {
        this.$toast.error(`本月上旬设定数字必须在1-10之间`)
        return;
      }

      // 检查第二行的三个数字是否都不超过 20
      if (this.fix_nums[1].some(num => parseInt(num) > 20) || this.fix_nums[1].some(num => parseInt(num) < 11)) {
        this.$toast.error(`本月中旬设定数字必须在11-20之间`)
        return;
      }

      // 检查第三行的三个数字是否都不超过 30
      if (this.fix_nums[2].some(num => parseInt(num) > this.lastday) || this.fix_nums[2].some(num => parseInt(num) < 21)) {
        this.$toast.error(`本月下旬设定数字必须在21-${this.lastday}之间`)
        return;
      }

      // fix-nums
      this.postLuckyAppData('fix-nums', JSON.stringify(this.fix_nums)).then(result => {
        if (result.success) {
          console.log('Post was successful:', result.data);
        } else {
          console.log('Post failed:', result.error);
        }
      });

      // start-times
      this.postLuckyAppData('start-times', JSON.stringify([0,0,0])).then(result => {
        if (result.success) {
          console.log('Post start-times was successful:', result.data);
        } else {
          console.log('Post  start-times failed:', result.error);
        }
      });

      //this.postLuckyAppData("start-times",JSON.stringify([0,0,0]))
      //localStorage.setItem('start-times',JSON.stringify([0,0,0]))
      this.$toast('设置保存成功')

    },

    getLuckyAppData(key) {
      const url = '/api/lucky-app/query';
      const params = new URLSearchParams({
        key: '4A6F70D4-54C9-440C-823A-01FB1BC33C54',
        name: key
      });

      return fetch(`${url}?${params.toString()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // 如果需要其他header，可以在这里添加
        }
      })
          .then(response => response.json())  // 解析响应
          .then(data => {
            return data.data.value; // 返回实际的值
          })
          .catch(error => {
            return '[["","",""],["","",""],["","",""]]'; // 返回默认值
          });
    },

    loadData(){

      this.getLuckyAppData('fix-nums').then(
          value=>{
            try {
              this.fix_nums = JSON.parse(value)
            }
            catch(e){
              this.fix_nums = [["","",""],["","",""],["","",""]]
            }
          }
      )

    },

    acceptValue1(val){ //接收当前的数值展示到页面
      this.currentNum1 =val

      if(this.buttonIdx=='first') this.results.nums_first[0] = val
      if(this.buttonIdx=='middle') this.results.nums_middle[0] = val
      if(this.buttonIdx=='last') this.results.nums_last[0] = val

    },
    acceptValue2(val){ //接收当前的数值展示到页面
      this.currentNum2 =val
      if(this.buttonIdx=='first') this.results.nums_first[1] = val
      if(this.buttonIdx=='middle') this.results.nums_middle[1] = val
      if(this.buttonIdx=='last') this.results.nums_last[1] = val
    },
    acceptValue3(val){ //接收当前的数值展示到页面
      this.currentNum3 =val
      if(this.buttonIdx=='first') this.results.nums_first[2] = val
      if(this.buttonIdx=='middle') this.results.nums_middle[2] = val
      if(this.buttonIdx=='last') this.results.nums_last[2] = val
    },



    goChoujiang(){ //更改抽奖

      if (!this.isstarted) {
        this.isstarted = true;
        this.$refs.count1.start();
        this.$refs.count2.start();
        this.$refs.count3.start();
      }

    },

    addStartTimes(start_times){

      if (!start_times) return;

      var newStartTimes = []

      if (this.buttonIdx == 'first')
        newStartTimes = [start_times[0]+1,start_times[1],start_times[2]]
      else if (this.buttonIdx == 'middle')
        newStartTimes = [start_times[0],start_times[1]+1,start_times[2]]
      else if (this.buttonIdx == 'last')
        newStartTimes = [start_times[0],start_times[1],start_times[2]+1]

      //localStorage.setItem('start-times',JSON.stringify(newStartTimes))

      this.postLuckyAppData('start-times', JSON.stringify(newStartTimes)).then(result => {
        if (result.success) {
          console.log('Post start-times was successful:', result.data);
        } else {
          console.log('Post  start-times failed:', result.error);
        }
      });

    },

    async onKeydown(e,buttonIdx){

      const value =  await  this.getLuckyAppData('start-times')
      var start_times = JSON.parse(value)

      //是否强制随机
      var mustRandom = false
      if (!start_times ||
          (buttonIdx == 'first' && start_times[0]>=2) ||
          (buttonIdx == 'middle' && start_times[1]>=2) ||
          (buttonIdx == 'last' && start_times[2]>=2))
          mustRandom = true

      //没有启动，先启动
      if (!this.isstarted) {

        this.buttonIdx = buttonIdx
        this.setFromValue()

        this.isstarted = true;
        this.$refs.count1.start();
        this.$refs.count2.start();
        this.$refs.count3.start();
      }

      else {
        this.addStartTimes(start_times)

        //回车键停止（随机模式）
        if (mustRandom) {
          await this.$refs.count1.stopNonValuePromise([]);
          await this.$refs.count2.stopNonValuePromise([this.currentNum1]);
          await this.$refs.count3.stopNonValuePromise([this.currentNum1,this.currentNum2]);

          this.isstarted = false;
          this.$nextTick(()=>{this.buttonIdx = null})
        }

        //空格键停止（指定模式）
        else {

          if (this.buttonIdx == 'first') {
            this.$refs.count1.stopValue(this.fix_nums[0][0]);
            this.$refs.count2.stopValue(this.fix_nums[0][1]);
            this.$refs.count3.stopValue(this.fix_nums[0][2]);
          }

          if (this.buttonIdx == 'middle') {
            this.$refs.count1.stopValue(this.fix_nums[1][0]);
            this.$refs.count2.stopValue(this.fix_nums[1][1]);
            this.$refs.count3.stopValue(this.fix_nums[1][2]);
          }

          if (this.buttonIdx == 'last') {
            this.$refs.count1.stopValue(this.fix_nums[2][0]);
            this.$refs.count2.stopValue(this.fix_nums[2][1]);
            this.$refs.count3.stopValue(this.fix_nums[2][2]);
          }

          var that = this;
          setTimeout(function(){
            that.isstarted = false;
            that.buttonIdx = null
          },100)
        }

      }

    }

  },
}
</script>


<style scoped>
.period-setting{
  color: white;
  margin-right: 10px;
  font-size: 24px;
  font-weight: bold;
  display: inline-block;
  width: 80px;
}
.cnt{
  color:white;
  font-weight: bold;
  margin-right:10px;
  font-size:24px;
}
.input-num{
  height: 45px;
  line-height: 45px;
  border-radius: 10px;
  margin-right: 10px;
  border: none;
  font-size: 24px;
  width: 80px;
  text-align: center;
  color: #6e90da;
  font-weight: bold;
}
.control-area{
  margin-top:30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: center;
}
.result-tag-red {
  display: inline-block;        /* 让 span 标签表现得像块级元素 */
  background-color: pink;      /* 设置背景色为粉色 */
  color: #d6006f;               /* 设置文字颜色为深粉色 */
  padding: 5px 10px;            /* 添加内边距，左右各10px，上下各5px */
  border-radius: 5px;           /* 设置圆角边框，使其边角更柔和 */
  font-size: 14px;              /* 设置文字大小 */
  font-weight: bold;            /* 设置文字加粗 */
  text-align: center;           /* 文字居中 */
  margin:10px 10px 0 10px;
}
.result-tag-green {
   display: inline-block;
   background-color: lightgreen;  /* 背景色设置为浅绿色 */
   color: green;                  /* 文字颜色为绿色 */
   padding: 5px 10px;
   border-radius: 5px;
   font-size: 14px;
   font-weight: bold;
   text-align: center;
  margin:10px 10px 0 10px;
 }
.result-tag-blue {
  display: inline-block;
  background-color: lightcyan;   /* 背景色设置为浅青色 */
  color: deepskyblue;             /* 文字颜色为深天蓝 */
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  margin:10px 10px 0 10px;
}
.changeNum{
  color:#6e90da;
  font-size: 10em;
  border: 1px solid #6e90da;
  width:100%;
  border-radius: 50px;
  margin-top:100px;
  margin-bottom:100px;
}
.btn{
  background-color: #6e90da;
  color:white;
  border: none;
  border-radius: 10px;
  height: 50px;
  width: 280px;
  font-size: 24px;
  font-weight: bold;
  margin-left:10px;
  margin-right:10px;
}

/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/ input[type='number'] {
  -moz-appearance: textfield;
}
</style>