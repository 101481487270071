import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import Toast from "vue-toastification";

// Import the CSS or use your own!
import "vue-toastification/dist/index.css";


const options = {
  // You can set your default options here
};

Vue.use(Toast, options);

new Vue({
  render: h => h(App),
}).$mount('#app')
