<template>
   <div style="width:20%;padding: 10px;box-sizing: border-box;">
   <slot></slot>
   </div>
</template>

<script>
export default {
  name:'countUp',
  props:{
    lastSymbol:{
      type:String,
      default:""
    },
    value:{ //滚动结束最终显示数字
      type:[String,Number],
      default:31,
    },
    from:{ // 开始时的数字
      type:[String,Number],
      default:0
    },
    speed:{ // 总时间
      type:[String,Number],
      default:60000,
    },
    refreshInterval:{ // 刷新一次的时间
      type:[String,Number],
      default:100,
    },
    beforeSize:{
      //小数点前最小显示位数，不足的话用0代替
      type:[String,Number],
      default:0
    },
    decimals:{ // 小数点后的位数，小数做四舍五入
      type:[String,Number],
      default:2
    },
  },
  data(){
    return{
      loops:'', //刷新次数
      increment:'', //刷新一次增加的数值
      loopCount:'', //记录刷新的次数
      CurrentValue:'',//开始时候的数字
      interval:'', //定时器
      sizeNum:'',//当前数字的长度
      sizeNumBefore:'', //当前数字小数点前的位数
    }

  },
  watch:{
  },

  methods:{
    start(){
      /*
      this.loops = Math.ceil(this.speed / this.refreshInterval)//刷新次数

      this.increment = (this.value - this.from)/this.loops//（结束的数字-开始的数字）/刷新次数 ，刷新一次增加的数值

      this.loopCount = 0 //记录刷新的次数

       */
      this.CurrentValue = this.from //开始时候的数字
      this.interval = setInterval(this.updateTimer,this.refreshInterval) //设置定时器，没个一段时间就执行
    },

    stopValue(value){
      const that = this;

      clearInterval(this.interval);
      this.interval = null;

      //必须停留在某个值
      if (value)
        setTimeout(function(){
          that.CurrentValue = value;
          that.$emit('sendValue',that.CurrentValue)
        },that.interval+50)
    },

    stopNonValue(nonvalues){
      const that = this;

      clearInterval(this.interval);
      this.interval = null;

      //确保不在nonvalues中间
      if (nonvalues)
        setTimeout(function(){
         that.updateTimer(nonvalues)
        },that.interval+50)

    },

    stopNonValuePromise(nonvalues){

      const that = this;
      return new Promise((resolve)=>{

        clearInterval(that.interval);
        that.interval = null;

        //确保不在nonvalues中间
        if (nonvalues)
          setTimeout(function(){
            that.updateTimer(nonvalues)
            resolve();
          },50)
        else
          resolve();
      })

    },

    updateTimer(nonvalues){ //刷新一次数值叠加

      //修改为获取一个小于value的随机数字，但是不能和传入的数组重复
      if (nonvalues) {
        do {
          this.CurrentValue = Math.floor(Math.random() * (this.value - this.from)) + (this.from + 1);
        } while (nonvalues.includes(this.CurrentValue));
      }
      else
        this.CurrentValue = Math.floor(Math.random() * (this.value - this.from)) + (this.from + 1);

      //发送显示
      this.$emit('sendValue',this.CurrentValue)

      /*
      var tim = this.CurrentValue.toFixed(this.decimals) //对当前值进行四舍五入 ,tim四射物质之后的当前数值

      this.sizeNum = String(tim).length;

      this.sizeNumBefore = this.sizeNum - this.decimals - 1;

      if(this.sizeNumBefore>=this.beforeSize){ //当前数字的小数点位数》=要求的小数点前位数

        this.$emit('sendValue',tim + this.lastSymbol)

      }else{

        tim = Array(this.beforeSize-this.sizeNumBefore + 1).join('0') + tim;
        this.$emit('sendValue',tim + this.lastSymbol)

      }

      if(Number(this.loopCount)>=Number(this.loops)){ //清楚定时器

        clearInterval(this.interval);

      }
       */

    }

  },

}
</script>
<style scoped>
</style>