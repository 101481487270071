<template>
  <div id="app">
    <img alt="Vue logo" src="http://public.wencaishui.com/images/wcs/V5/radom_banner.jpg" style="width:40%;border-radius: 50px;">
    <HelloWorld msg="按空格键立即开始"/>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
